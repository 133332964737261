.contact-container {
    padding-left: var(--container-padding-left);
    padding-right: var(--container-padding-right);
    padding-top: var(--container-padding-top);
    padding-bottom: var(--container-padding-bottom);
    display: var(--container-display);
    gap: var(--container-gap);
    background-color: var(--primary-color);
}

.contact-section {
    display: var(--section-display);
    gap: var(--section-gap);
    flex-direction: column;
}

.contact-list {
    display: flex; 
    flex-direction: row; 
    justify-content: space-between; 
    align-items: flex-start; 
    gap: 3rem;
}

.contact-section .contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3rem;
    flex-basis: 50%;
}

.contact-section .contact .contact-image img {
    width: var(--icon-size-xlarge);
    height: auto;
}

.contact-section .contact .contact-text {
    display: var(--sub-section-display);
    flex-direction: column;
}

.contact h2 {
    font-family: var(--button-font-family);
    font-size: var(--button-font-size);
    display: block;
    color: #ffffff;
    font-weight: light;
}

.contact p {
    font-size: var(--font-size-medium);
    font-family: var(--font-family-secondary);
    line-height: var(--font-line-height);
    color: #fff;
    display: block;
}

.contact p b {
    color: #9AD6C7;
    font-weight: bolder;
}


@media (max-width: 1024px) {
    .contact-container {
        flex-basis: var(--mobile-container-flex-basis);
        flex-direction: var(--mobile-container-flex-direction);
        padding: var(--mobile-container-padding);
    }

    .contact-list {
        flex-direction: column;
    }
    
    .contact-section .contact-list .contact .contact-image {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .contact-section .contact-list .contact {
        gap: var(--mobile-spacing-small);
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-basis: 50%;
    }

    .contact-section .contact-list .contact h2 {
        font-size: var(--mobile-font-size-large);
        font-weight: light;
    }

    /* 
    }

    .contact-section .contact .contact-image {
        display: none;
    }
    
    .contact p {
        font-size: 0.85rem;
        line-height: 1.25;
    } */
}