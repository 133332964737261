* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, Helvetica, sans-serif;
}

header {
    padding-left: var(--spacing-xxlarge);
    padding-right: var(--spacing-xxlarge);
    padding-bottom: var(--spacing-medium);
    padding-top: var(--spacing-medium);
    background-color: #fff;
    text-align: center;
}

.hamburger {
    display: none;
}

header nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header nav .logo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: var(--spacing-medium);
}

header nav .logo a img {
    width: auto;
    height: var(--icon-size-xlarge);
}

header nav .logo p {
    font-size: var(--font-size-xxxlarge);
    font-family: var(--font-family-header);
    color: var(--primary-color);
    display: flex;
    align-items: center;
    font-weight: lighter;
}

header nav ul {
    list-style: none;
    display: flex;
}

header nav ul li button {
    color: var(--primary-color);
    font-family: var(--button-font-family);
    font-size: var(--button-font-size);
    padding: var(--button-padding);
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
}

header nav ul li button:hover {
    background-color: var(--primary-color);
    color: #fff;
    text-decoration: none;
}

.hero-container {
    padding-left: var(--container-padding-left);
    padding-right: var(--container-padding-right);
    padding-top: var(--container-padding-top);
    padding-bottom: var(--container-padding-bottom);
    display: var(--container-display);
    gap: var(--container-gap);
    background-color: #000;
    align-items: center;
    justify-content: space-between;
}

.hero-text {
    display: flex;
    flex-direction: column;
    flex-basis: 60%;
    gap: 1rem;
}

.hero-text h1 {
    display: none;
}

.hero-text h2 {
    font-family: "Playfair Display", serif;
    text-align: left;
    font-size: 3rem;
    font-weight: lighter;
    color: #d2d2d2;
    line-height: 1;
}

.hero-text p {
    font-size: var(--font-size-medium);
    font-family: var(--font-family-secondary);
    text-align: left;
    color: #d2d2d2;
    font-weight: 100;
}

.hero-image {
    display: flex;
    justify-content: center;
    flex-basis: 60%;
}

.hero-image img {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.atendimento-button {
    background-color: var(--primary-color);
    padding: var(--button-padding);
    /* border-radius: var(--button-border-radius); */
    font-family: var(--font-family-secondary);
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    letter-spacing: 0.1em;
    flex-shrink: 0;
    flex-grow: 0;
    align-self: flex-start;
}

.atendimento-button:hover {
    background-color: #ffffff;
    color: var(--primary-color);
}

.back-to-top {
    width: var(--icon-size-medium);
    height: var(--icon-size-medium);
    background-color: var(--primary-color);
    border-radius: var(--button-border-radius);
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    opacity: 0.7;
    display: flex;
    z-index: 100;
    justify-content: center;
    align-items: center;
}

.back-to-top::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 0.6rem solid transparent;
    border-right: 0.6rem solid transparent;
    border-bottom: 1rem solid #ffffff;
    position: relative;
}

.back-to-top:hover {
    opacity: 1;
}

/* Media Queries for Responsiveness */
@media (max-width: 1100px) {
    /* header */
    header {
        display: var(--mobile-container-display);
        flex-basis: var(--mobile-container-flex-basis);
        flex-direction: var(--mobile-container-flex-direction);
        padding: var(--mobile-container-padding);
    }

    header nav {
        gap: var(--mobile-spacing-medium);
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0rem;
    }

    header nav .logo p {
        display: none;
    }

    header nav ul.menu {
        display: none;
        flex-direction: column;
        width: 100%;
        transition: max-height 0.5s ease-in-out;
        overflow: hidden;
        max-height: 0rem;
    }

    header nav ul.menu.visible {
        gap: var(--mobile-spacing-small);
        display: flex;
        justify-content: flex-start;
        max-height: 13rem;
    }

    header nav ul.menu.visible li {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    header nav ul.menu.visible li button {
        font-size: var(--mobile-font-size-xlarge);
        padding: 0;
    }

    /* hamburger menu */
    .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: absolute !important;
        top: 7px;
        right: 10px;
        width: 5rem;
        height: 5rem;
        cursor: pointer;
        padding: 1rem;
    }

    .hamburger:focus {
        outline: none;
    }

    .line {
        width: 100%;
        height: 0.3rem;
        background-color: #501357;
        border-radius: 0.2rem;
        transition: all 0.5s ease-in-out;
    }

    .clicked .line-1 {
        transform: rotateZ(-405deg) translate(-0.8rem, 0.6rem);
    }

    .clicked .line-2 {
        opacity: 0;
    }

    .clicked .line-3 {
        transform: rotateZ(405deg) translate(-0.8rem, -0.6rem);
    }

    /* hero */
    .hero-container {
        display: var(--mobile-container-display);
        flex-basis: var(--mobile-container-flex-basis);
        flex-direction: var(--mobile-container-flex-direction);
        position: relative;
        flex-direction: column;
        padding: 0;
    }

    .hero-image img {
        object-fit: cover;
        object-position: 50% 40%;
        height: 40em;
    }

    .hero-image::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
    }

    .hero-text {
        gap: var(--mobile-spacing-small);
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 10%;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
        z-index: 5;
    }

    .hero-text h1 {
        font-family: var(--font-family-header);
        font-size: var(--mobile-font-size-xxlarge);
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        color: #ffffff;
        font-weight: lighter;
    }

    .hero-text h2 {
        font-size: var(--mobile-font-size-large);
        background: rgba(0, 0, 0, 0.5);
    }

    .hero-text p {
        font-size: var(--mobile-font-size-small);
        background: rgba(0, 0, 0, 0.5);
    }

    .atendimento-button {
        font-size: var(--mobile-font-size-xlarge);
    }
}