@import url('https://fonts.googleapis.com/css2?family=Gravitas+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play&display=swap');

.footer-container {
    background-color: #000;
    padding-left: var(--container-padding-left);
    padding-right: var(--container-padding-right);
    padding-top: var(--container-padding-top);
    padding-bottom: var(--container-padding-bottom);
    display: var(--container-display);
    gap: var(--container-gap);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-section {
    display: var(--section-display);
    gap: var(--section-gap);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-section .footer-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #FFDB58;
    gap: 0.5rem;
}


.footer-section .footer-text a {
    font-family: 'Play', sans-serif;
    font-size: 0.75rem;
    text-decoration: none;
    color: #FFDB58;
}

.footer-section .footer-text p {
    font-family: 'Raleway', sans-serif;
    font-size: 0.75rem;
    text-transform: uppercase;
}


@media (max-width: 1024px) {
    .footer-container {
        flex-basis: var(--mobile-container-flex-basis);
        flex-direction: var(--mobile-container-flex-direction);
        padding: var(--mobile-container-padding);
    }
}