.whatsapp-container {
    padding-left: var(--container-padding-left);
    padding-right: var(--container-padding-right);
    padding-top: var(--container-padding-top);
    padding-bottom: var(--container-padding-bottom);
    display: var(--container-display);
    gap: var(--container-gap);
    background-color: #000;
    flex-direction: column;
}

.whatsapp-container p {
    font-family: var(--font-family-primary);
    font-size: var(--font-size-xlarge);
    color: #fff;
    line-height: 1.25;
    text-align: center;
}

.whatsapp-container .whatsapp-list {
    display: flex; 
    flex-direction: row; 
    justify-content: space-evenly; 
    align-items: flex-start; 
    gap: 3rem;
}

.whatsapp-section {
    display: var(--section-display);
    gap: var(--section-gap);
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.whatsapp-section a {
    display: var(--sub-section-display);
    gap: var(--sub-section-gap);
    font-family: var(--button-font-family);
    font-size: var(--button-font-size);
    flex-direction: row;
    color: #fff;
    text-decoration: none;
    align-items: center;
    justify-content: center;
}

.whatsapp-section .whatsapp-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.whatsapp-section .whatsapp-image img {
    width: auto;
    height: var(--icon-size-large);
}



@media (max-width: 1000px) {
    .whatsapp-container {
        flex-basis: var(--mobile-container-flex-basis);
        flex-direction: var(--mobile-container-flex-direction);
        padding: var(--mobile-container-padding);
    }

    .whatsapp-container .whatsapp-list {
        display: flex; 
        flex-direction: column; 
        align-items: left;
        gap: 1rem;
        margin-left: 1rem;
    }

    .whatsapp-section {
        gap: 1rem;
    }

    .whatsapp-container .whatsapp-list .whatsapp-section a {
        gap: 1rem;
    }

    .whatsapp-container .whatsapp-list .whatsapp-section .whatsapp-text p {
        font-size: var(--mobile-font-size-medium);
        text-align: left
    }

    .whatsapp-container p {
        font-size: var(--mobile-font-size-medium);
    }

    .whatsapp-section .whatsapp-image img {
        width: auto;
        height: var(--icon-size-small);
    }
}