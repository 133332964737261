@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');

.typed-effect-container {
    padding-left: var(--container-padding-left);
    padding-right: var(--container-padding-right);
    padding-top: var(--container-padding-top);
    padding-bottom: var(--container-padding-bottom);
    background-color: #fff;
}

.typed-text {
    font-family: var(--font-family-primary);
    font-size: var(--font-size-xlarge);
    color: var(--primary-color);
    line-height: 1.25;
}

@media (max-width: 800px) {
    .typed-effect-container {
        flex-basis: var(--mobile-container-flex-basis);
        flex-direction: var(--mobile-container-flex-direction);
        padding: var(--mobile-container-padding);
        background-color: #fff;
    }

    .typed-text {
        font-size: var(--mobile-font-size-medium);
        text-align: left;
    }
}